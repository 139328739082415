.navi-collapse {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	position: relative;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow-x: hidden;
	vertical-align: middle;

	& > .header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 50px;
		padding: 0 16px 0 20px;
		cursor: pointer;

		& > .arrow {
			transition: transform 0.3s ease;
			transform: rotate(90deg);

			&.open {
				transform: rotate(-90deg);
			}
		}

		& > .title {
			font-size: 16px;
			font-weight: 300;
			letter-spacing: 1px;
			color: var(--Dark);
		}
	}

	& > .list {
		background-color: #fafafa;
		will-change: transform, opacity, height;
		overflow: hidden;

		& > div {
			display: flex;
			flex-direction: column;
		}
	}
}

.nav-item {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	margin-left: 28px;
	padding: 0 20px 0 16px;
	height: 50px;
	font-size: 16px;
	font-weight: 300;
	letter-spacing: 1px;
	color: var(--Dark);
	border-left: 1px solid var(--Primary);
	transition: background-color 0.3s ease;
}
