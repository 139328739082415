

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;700&display=swap');
/*
 * This is for global css usage.
 */
*,
*::after,
*::before {
	box-sizing: border-box;
	font-family: 'Noto Sans TC', 'PingFangTC-Light', 'Microsoft JhengHei', sans-serif, 'Montserrat';
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	outline: none;
}
body,
input,
select,
textarea,
button {
	-webkit-font-smoothing: antialiased;
	font-family: 'Noto Sans TC', 'PingFangTC-Light', 'Microsoft JhengHei', sans-serif;
}
body {
	margin: 0;
	overflow-x: hidden;
	overflow-y: overlay;
	width: 100%
}
body:global(.no-scroll) {
		position: fixed;
		overflow-y: scroll;
	}
a {
	text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure {
	margin: 0;
	padding: 0;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9nbG9iYWwuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7O0FBSUEsK0dBQStHO0FBQy9HLHlHQUF5RztBQUx6Rzs7R0FFRztBQUtIOzs7Q0FHQyx1QkFBdUI7Q0FDdkIsZ0dBQWdHO0NBQ2hHLG9CQUFvQjtDQUNwQixtQkFBbUI7Q0FDbkIscUJBQXFCO0NBQ3JCLG9CQUFvQjtDQUNwQixjQUFjO0NBQ2Q7QUFFRDs7Ozs7Q0FLQyxvQ0FBb0M7Q0FDcEMsa0ZBQWtGO0NBQ2xGO0FBRUQ7Q0FDQyxVQUFVO0NBQ1YsbUJBQW1CO0NBQ25CLG9CQUFvQjtDQUNwQixXQUFZO0NBTVo7QUFKQTtFQUNDLGdCQUFnQjtFQUNoQixtQkFBbUI7RUFDbkI7QUFHRjtDQUNDLHNCQUFzQjtDQUN0QjtBQUVEOzs7Ozs7OztDQVFDLFVBQVU7Q0FDVixXQUFXO0NBQ1giLCJmaWxlIjoic3JjL2dsb2JhbC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIvKlxuICogVGhpcyBpcyBmb3IgZ2xvYmFsIGNzcyB1c2FnZS5cbiAqL1xuXG5AaW1wb3J0IHVybCgnaHR0cHM6Ly9mb250cy5nb29nbGVhcGlzLmNvbS9jc3MyP2ZhbWlseT1Ob3RvK1NhbnMrVEM6d2dodEAxMDA7MzAwOzQwMDs1MDA7NzAwOzkwMCZkaXNwbGF5PXN3YXAnKTtcbkBpbXBvcnQgdXJsKCdodHRwczovL2ZvbnRzLmdvb2dsZWFwaXMuY29tL2NzczI/ZmFtaWx5PU1vbnRzZXJyYXQ6d2dodEAxMDA7MzAwOzQwMDs1MDA7NzAwJmRpc3BsYXk9c3dhcCcpO1xuXG4qLFxuKjo6YWZ0ZXIsXG4qOjpiZWZvcmUge1xuXHRib3gtc2l6aW5nOiBib3JkZXItYm94O1xuXHRmb250LWZhbWlseTogJ05vdG8gU2FucyBUQycsICdQaW5nRmFuZ1RDLUxpZ2h0JywgJ01pY3Jvc29mdCBKaGVuZ0hlaScsIHNhbnMtc2VyaWYsICdNb250c2VycmF0Jztcblx0Zm9udC13ZWlnaHQ6IG5vcm1hbDtcblx0Zm9udC1zdHlsZTogbm9ybWFsO1xuXHRmb250LXN0cmV0Y2g6IG5vcm1hbDtcblx0bGluZS1oZWlnaHQ6IG5vcm1hbDtcblx0b3V0bGluZTogbm9uZTtcbn1cblxuYm9keSxcbmlucHV0LFxuc2VsZWN0LFxudGV4dGFyZWEsXG5idXR0b24ge1xuXHQtd2Via2l0LWZvbnQtc21vb3RoaW5nOiBhbnRpYWxpYXNlZDtcblx0Zm9udC1mYW1pbHk6ICdOb3RvIFNhbnMgVEMnLCAnUGluZ0ZhbmdUQy1MaWdodCcsICdNaWNyb3NvZnQgSmhlbmdIZWknLCBzYW5zLXNlcmlmO1xufVxuXG5ib2R5IHtcblx0bWFyZ2luOiAwO1xuXHRvdmVyZmxvdy14OiBoaWRkZW47XG5cdG92ZXJmbG93LXk6IG92ZXJsYXk7XG5cdHdpZHRoOiAxMDAlO1xuXG5cdCY6Z2xvYmFsKC5uby1zY3JvbGwpIHtcblx0XHRwb3NpdGlvbjogZml4ZWQ7XG5cdFx0b3ZlcmZsb3cteTogc2Nyb2xsO1xuXHR9XG59XG5cbmEge1xuXHR0ZXh0LWRlY29yYXRpb246IG5vbmU7XG59XG5cbmgxLFxuaDIsXG5oMyxcbmg0LFxuaDUsXG5oNixcbnAsXG5maWd1cmUge1xuXHRtYXJnaW46IDA7XG5cdHBhZGRpbmc6IDA7XG59XG4iXX0= */