/*
 * This is for global css usage.
 */

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;700&display=swap');

*,
*::after,
*::before {
	box-sizing: border-box;
	font-family: 'Noto Sans TC', 'PingFangTC-Light', 'Microsoft JhengHei', sans-serif, 'Montserrat';
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	outline: none;
}

body,
input,
select,
textarea,
button {
	-webkit-font-smoothing: antialiased;
	font-family: 'Noto Sans TC', 'PingFangTC-Light', 'Microsoft JhengHei', sans-serif;
}

body {
	margin: 0;
	overflow-x: hidden;
	overflow-y: overlay;
	width: 100%;

	&:global(.no-scroll) {
		position: fixed;
		overflow-y: scroll;
	}
}

a {
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
figure {
	margin: 0;
	padding: 0;
}
