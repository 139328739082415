.header.desktop {
	display: flex;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 80px;
	padding: 0 60px;
	color: var(--Primary);
	background-color: var(--White);
	border-bottom: 1px solid #f2f2f2;
	transition: all 0.3s ease;
	z-index: 800;

	@media screen and (--tablet) {
		padding: 0 20px;
		height: 64px;
	}

	& > .logo {
		display: flex;
		justify-content: center;
		align-items: center;

		& > .logo-icon {
			width: 136px;
			height: 20px;

			@media screen and (--tablet) {
				width: 149.7px;
				height: 22px;
			}
		}
	}

	& > .navigation {
		margin: 0 60px;

		& a {
			color: var(--Dark);
		}
	}

	& > .group {
		display: flex;
		align-items: center;
		margin-left: auto;

		& > :not(:last-child) {
			margin-right: 30px;
		}

		& > a {
			&:not(:first-child) {
				height: 24px;
			}
		}

		& .calendar {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	/* & .calendar-icon:hover {
		& > svg {
			& path {
				fill: var(--Dark);
			}
		}
	} */

	&.transparent {
		color: var(--White);
		background-color: transparent;
		border-bottom: 1px solid transparent;

		& > .navigation {
			& a {
				color: var(--White);
			}
		}

		& .calendar-icon,
		& .question-icon,
		& .member-icon {
			& > svg {
				& path {
					fill: var(--White);
				}
			}
		}
	}

	&.hidden {
		transform: translateY(-100%);
	}
}

.header.mobile {
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 64px;
	color: var(--Primary);
	background-color: rgba(255, 255, 255, 1);
	border-bottom: 1px solid #f2f2f2;
	transition: background-color 0.3s ease, border-bottom 0.5s ease;
	overflow: hidden;
	z-index: 800;

	&.open {
		z-index: 900;
	}

	& > .main {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 64px;
		padding: 0 20px;
		z-index: 1;

		& > .logo {
			display: flex;
			justify-content: center;
			align-items: center;

			& > .logo-icon {
				width: 149.7px;
				height: 22px;
			}
		}

		& > .hamburger {
			margin-left: auto;
		}
	}

	& > .nav-content {
		display: flex;
		flex: 1;
		flex-direction: column;
		flex-shrink: 0;
		width: 100%;
		padding: 0;
		overflow-y: scroll;
		-ms-overflow-style: none;
		-webkit-overflow-scrolling: touch;

		&::-webkit-scrollbar {
			display: none;
		}

		& > .container {
			display: flex;
			flex-direction: column;
			height: calc(100vh - 140px);
			position: relative;

			& > .wrapper {
				display: flex;
				flex-wrap: wrap;
				flex: 1;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				& > .navi-locale {
					height: calc(100vh - 140px);
				}
			}
		}

		& > .bottom-group {
			display: flex;
			justify-content: space-between;
			margin-top: auto;
			padding: 14px 16px 40px 20px;
			z-index: 1;
			background-color: white;

			& > .group {
				display: flex;
				align-items: center;
				justify-content: center;

				& :not(:last-child) {
					margin-right: 16px;
				}
			}

			& .link {
				display: flex;
				flex-shrink: 0;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				letter-spacing: 0.8px;
				color: var(--Dark);
				cursor: pointer;
				position: relative;
				-webkit-tap-highlight-color: transparent;

				&.primary {
					color: var(--Primary);
				}

				&.with-icon {
					& > span {
						margin-left: 8px;
					}
				}
			}
		}
	}

	&.transparent {
		transition: background-color 0.3s ease, border-bottom 0.1s ease;
		background-color: rgba(255, 255, 255, 0);
		border-bottom: 1px solid rgba(255, 255, 255, 0);
	}
}
