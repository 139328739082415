.root {
	position: relative;
	min-height: 100vh;
	background-image: url('../../images/contour-map.png');
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat
}
@media screen and (max-width: 800px) {
	.root {
		background-image: none
}
	}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9sYXlvdXRzL1Jvb3QvaW5kZXguY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0NBQ0MsbUJBQW1CO0NBQ25CLGtCQUFrQjtDQUNsQixzREFBc0Q7Q0FDdEQsNkJBQTZCO0NBQzdCLDRCQUE0QjtDQUM1Qix1QkFBdUI7Q0FDdkIsNEJBQTZCO0NBSzdCO0FBSEE7Q0FURDtFQVVFLHNCQUF1QjtDQUV4QjtFQURDIiwiZmlsZSI6InNyYy9sYXlvdXRzL1Jvb3QvaW5kZXguY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnJvb3Qge1xuXHRwb3NpdGlvbjogcmVsYXRpdmU7XG5cdG1pbi1oZWlnaHQ6IDEwMHZoO1xuXHRiYWNrZ3JvdW5kLWltYWdlOiB1cmwoJy4uLy4uL2ltYWdlcy9jb250b3VyLW1hcC5wbmcnKTtcblx0YmFja2dyb3VuZC1hdHRhY2htZW50OiBmaXhlZDtcblx0YmFja2dyb3VuZC1wb3NpdGlvbjogY2VudGVyO1xuXHRiYWNrZ3JvdW5kLXNpemU6IGNvdmVyO1xuXHRiYWNrZ3JvdW5kLXJlcGVhdDogbm8tcmVwZWF0O1xuXG5cdEBtZWRpYSBzY3JlZW4gYW5kICgtLXBob25lKSB7XG5cdFx0YmFja2dyb3VuZC1pbWFnZTogbm9uZTtcblx0fVxufVxuIl19 */