.sub-navigation {
	width: 100%;
	transition: all 0.1s ease;
	position: sticky;
	top: 0;
	z-index: 750;
	height: 0;

	@media screen and (--tablet), screen and (--phone) {
		top: 64px;
	}

	& > .container {
		width: 100%;
		background-color: #f2f2f2;
		overflow-x: auto;
		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			display: none;
		}

		& > .wrapper {
			display: flex;
			flex-wrap: nowrap;
			width: fit-content;

			@media screen and (--desktop) {
				padding: 0 60px;
			}

			@media screen and (--tablet), screen and (--phone) {
				padding: 0 30px;
			}
		}
	}
}

.link {
	display: inline-block;
	font-size: 14px;
	letter-spacing: 0.8px;
	color: var(--Dark_grey);
	padding: 15px 0;
	margin-right: 40px;

	&:last-child {
		margin-right: 0;
	}

	@media screen and (--tablet), screen and (--phone) {
		display: block;
		margin-right: 16px;
		white-space: nowrap;
		padding: 15px 0;
	}

	&.actived {
		color: var(--Dark);
		border-bottom: 3px solid var(--Primary);
	}
}
