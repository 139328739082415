.division {
	display: flex;
	flex-shrink: 0;
	width: 100%;
	height: 1px;

	&.default {
		background-color: #d8d8d8;
	}

	&.white {
		background-color: var(--White);
	}

	&.vertical {
		height: 100%;
		width: 1px;
	}

	&.blue {
		background-color: var(--Button_primary_blue);
	}
}
