.button-locale {
	display: inline-flex;
	align-items: center;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 1px;

	&.white {
		color: var(--White);
	}

	&.default {
		color: var(--Dark);
	}
}

.button-locale-content {
	padding: 0;
	z-index: 1000;

	& > .button-locale-selection {
		min-width: 100px;
		padding: 6px 0;

		& > .option {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 8px;
			height: 40px;
			font-size: 14px;
			font-weight: 300;
			letter-spacing: 0.8px;
			color: var(--Dark);
			cursor: pointer;
			white-space: nowrap;
			padding-right: 16px;
			padding-left: 16px;

			&.active,
			&:hover {
				color: var(--Primary);
			}
		}
	}
}
