.tooltip-trigger {
	display: inline-flex;
}

.tooltip-container {
	background-color: rgba(33, 33, 33, 0.8);
	color: var(--White);
	padding: 6px 8px;
	border-radius: 4px;
	box-shadow: none;
	border: none;
	font-size: 12px;
	letter-spacing: 0.2px;
	text-align: center;
	max-width: 80%;
}

:global(.tooltip-arrow)::after {
	border-style: none;
}

:global(.tooltip-arrow[data-placement*='top'])::before {
	border-color: rgba(33, 33, 33, 0.8) transparent transparent transparent;
	top: 0;
}

:global(.tooltip-arrow[data-placement*='left'])::before {
	border-color: transparent transparent transparent rgba(33, 33, 33, 0.8);
}

:global(.tooltip-arrow[data-placement*='right'])::before {
	border-color: transparent rgba(33, 33, 33, 0.8) transparent transparent;
}

:global(.tooltip-arrow[data-placement*='bottom'])::before {
	border-color: transparent transparent rgba(33, 33, 33, 0.8) transparent;
	top: 0;
}
