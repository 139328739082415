.toast {
	display: flex;
	align-items: flex-start;
	position: fixed;
	top: 120px;
	left: 50%;
	max-width: 280px;
	padding: 8px 12px 8px 10px;
	border-radius: 4px;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
	background-color: white;
	z-index: 1000;

	& > :not(:last-child) {
		margin-right: 8px;
	}

	& > div.text {
		font-size: 14px;
		letter-spacing: 0.8px;
		color: var(--Dark);
		text-align: left;
		flex: 1;
		flex-shrink: 0;
		align-self: center;
		word-wrap: break-word;
		min-width: 0;
	}

	& > .icon {
		width: 24px;

		&.warning {
			& svg path {
				fill: var(--Funcational_warning);
			}
		}

		&.pass {
			& svg path {
				fill: var(--Primary);
			}
		}
	}
}
