.icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	position: relative;

	&.normal {
		width: 24px;
	}

	&.small {
		width: 18px;
	}

	&.x-small {
		width: 14px;
	}

	&.large {
		width: 32px;
	}

	&.clickable {
		cursor: pointer;
	}

	& > svg {
		width: 100%;
		height: auto;
	}

	& > .badge {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		right: 0;
		top: 0;
		height: 24px;
		width: 24px;
		border-radius: 100%;
		color: var(--White);
		background-color: var(--Primary);
		font-family: 'Montserrat', sans-serif;
		font-size: 12px;
		font-weight: 600;
		text-align: center;
	}
}
