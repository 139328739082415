.dropdown-arrow-trigger {
	display: inline-flex;
}

.dropdown-arrow-content {
	position: fixed;
	padding: 8px;
	background-color: var(--White);
	color: white;
	text-align: center;
	font-size: 16px;
	border-radius: 4px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	opacity: 0;
	transition: opacity 0.5s ease;

	& > .arrow {
		position: absolute;
		height: 1rem;
		width: 1rem;
		bottom: 100%;
		margin-top: -0.4rem;

		&.start {
			left: 1rem;
		}

		&.end {
			right: 1rem;
		}

		&::before {
			display: block;
			content: '';
			position: absolute;
			bottom: 0;
			left: calc(50% - 0.5rem);
			height: 0;
			width: 0;
			margin: auto;
			border-style: solid;
			border-width: 0 0.5rem 0.4rem 0.5rem;
			border-color: transparent transparent var(--White) transparent;
		}

		&::after {
			display: block;
			content: '';
			height: 0;
			width: 0;
			margin: auto;
			position: absolute;
			border-style: none;
			border-color: transparent transparent var(--White) transparent;
			border-width: 0 0.5rem 0.4rem 0.5rem;
		}
	}
}
