.dropdown {
	position: relative;

	& > .content {
		width: 100%;
		cursor: pointer;

		&.default-cursor {
			cursor: default;
		}

		& > .label {
			font-size: 14px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.71;
			letter-spacing: normal;
			color: var(--Dark);
		}
	}

	& > .menu {
		position: absolute;
		width: 100%;
		z-index: 5;
		outline: none;

		&.placement-bottom {
			top: 100%;
		}

		&.placement-top {
			bottom: 100%;
		}

		&.alignment-start {
			left: 0;
		}

		&.alignment-end {
			right: 0;
		}

		&.open {
			z-index: 600;
		}
	}
}
