.cookie-consent {
	position: fixed;
	bottom: 0;
	padding: 20px;
	box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.04);
	background-image: linear-gradient(263deg, #2d8fe3, var(--Primary));
	color: #fff;
	z-index: 999;

	& h5 {
		font-size: 16px;
		margin-bottom: 10px;
	}

	& p {
		font-size: 14px;
	}

	@media screen and (--tablet), screen and (--desktop) {
		display: flex;
		align-items: center;
	}

	@media screen and (--desktop) {
		padding: 28px 50px;
	}
}

.button-group {
	display: flex;
	justify-content: center;
	margin-top: 20px;

	& button:not(:last-child) {
		margin-right: 20px;
	}

	& .button {
		padding-top: 8px;
		padding-bottom: 8px;
		height: 28px;
	}

	@media screen and (--tablet), screen and (--desktop) {
		flex-direction: column;
		margin-top: 0;
		margin-left: 20px;

		& button:not(:last-child) {
			margin-right: 0;
			margin-bottom: 20px;
		}
	}

	@media screen and (--desktop) {
		margin-left: 50px;
	}
}
