.page-wrapper {
	position: relative;
	width: 100%;

	&.with-header {
		@media screen and (--desktop) {
			padding-top: 80px;
		}

		@media screen and (--tablet), screen and (--phone) {
			padding-top: 63px;
		}

		&.with-subnav {
			@media screen and (--tablet), screen and (--phone) {
				padding-top: 113px;
			}
		}
	}

	&.min-view-height {
		min-height: 100vh;
	}

	@media screen and (--desktop) {
		padding-bottom: 100px;
	}

	@media screen and (--tablet) {
		padding-bottom: 60px;
	}

	@media screen and (--phone) {
		padding-bottom: 40px;
	}
}

.sectionWrapper {
	width: 100%;
	overflow: hidden;

	&.overflow {
		overflow: visible;
	}

	& > .wrapper {
		max-width: 1080px;
		padding: 0 20px;
		margin-right: auto;
		margin-left: auto;
	}

	&:not(.without-margin) {
		&:first-child {
			margin-top: 80px;

			@media screen and (--tablet) {
				margin-top: 60px;
			}

			@media screen and (--phone) {
				margin-top: 40px;
			}
		}

		&:not(:last-child) {
			margin-bottom: 80px;

			@media screen and (--tablet) {
				margin-bottom: 60px;
			}

			@media screen and (--phone) {
				margin-bottom: 40px;
			}
		}
	}
}
