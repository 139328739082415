.modal-trans-member {
	width: 372px;
	max-height: calc(100vh - 40px);
	padding: 32px 24px 28px 24px;

	& .container {
		width: 100%;
		font-size: 12px;
		font-weight: normal;
		line-height: normal;
		letter-spacing: 0.69px;
		color: #212121;

		& > .option {
			padding: 15px 0;
			cursor: pointer;

			&._active {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}

		& > .login {
			margin-top: 12px;
			padding-top: 24px;
			text-align: center;
			cursor: pointer;

			&::after {
				content: '';
				width: calc(100% + 64px);
				height: 1px;
				position: absolute;
				bottom: 68px;
				left: -40px;
				background-color: rgba(0, 0, 0, 0.1);
			}
		}
	}
}
