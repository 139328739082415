.button-member-trigger {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	& > .icon {
		&.white {
			& path {
				fill: white;
			}
		}

		&.black {
			& path {
				fill: var(--Dark40);
			}
		}
	}

	& > span {
		font-size: 14px;
		letter-spacing: 0.8px;
		color: var(--Dark);
	}

	& > :not(:last-child) {
		margin-right: 8px;
	}
}

.button-member-menu-panel {
	display: flex;

	&.alignment-end {
		justify-content: flex-end;
		margin-right: -6px;
	}

	&.alignment-start {
		justify-content: flex-start;
	}
}

.button-member-menu {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	width: 172px;
	padding: 0;
	background-color: white;
	border-radius: 4px;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
	transition: opacity 0.3s;
	z-index: 9999;

	&.with-max-height {
		max-height: calc(100vh - 150px);
		overflow-y: auto;
		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	& > .arrow {
		height: 0.5rem;
		position: absolute;
		width: 1rem;
		z-index: 1;

		&::before {
			display: block;
			border-style: solid;
			content: '';
			height: 0;
			margin: auto;
			width: 0;
		}

		&::after {
			display: block;
			border-style: solid;
			content: '';
			height: 0;
			margin: auto;
			position: absolute;
			width: 0;
		}

		&[data-placement*='top'] {
			bottom: 1px;
			height: 1rem;
			left: 8px;
			margin-bottom: -1rem;
			width: 1rem;
		}

		&[data-placement*='top']::before {
			border-color: #f5f5f5 transparent transparent transparent;
			border-width: 0.4rem 0.25rem 0 0.25rem;
			position: absolute;
			top: 1px;
		}

		&[data-placement*='top']::after {
			border-color: white transparent transparent transparent;
			border-width: 0.4rem 0.25rem 0 0.25rem;
		}
	}

	& > .member-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 18px 12px;
		position: relative;

		& > .member-name {
			display: flex;
			align-items: center;
			justify-content: space-between;
			overflow: hidden;

			& > svg {
				flex-shrink: 0;
			}

			& > span {
				margin-left: 8px;
				font-size: 14px;
				line-height: normal;
				letter-spacing: 0.8px;
				color: var(--Dark);
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}

		&::after {
			content: '';
			width: 100%;
			height: 1px;
			background-color: #f2f2f2;
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}

	& > .member-selection {
		position: relative;

		& .option {
			display: flex;
			align-items: center;
			height: 40px;
			font-size: 14px;
			font-weight: 300;
			letter-spacing: 0.8px;
			color: var(--Dark);
			cursor: pointer;
			white-space: nowrap;
			padding: 0 16px;

			&:hover,
			&.active {
				background-color: #fafafa;
			}

			& > svg {
				margin-right: 8px;
			}
		}

		&::after {
			content: '';
			width: 100%;
			height: 1px;
			background-color: #f2f2f2;
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}

	& .logout {
		padding: 12px 0;
		text-align: center;
		cursor: pointer;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: 0.69px;
		color: var(--Dark);
	}
}
