.modal-cover {
	display: grid;
	place-content: center;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 900;

	&::before {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(33, 33, 33, 0.6);
	}

	&.modal-white-cover {
		&::before {
			backdrop-filter: blur(10px);
			background-color: rgba(255, 255, 255, 0.6);
		}
	}
}

.modal {
	display: inline-flex;
	justify-content: center;
	flex-direction: column;
	position: relative;
	padding: 32px 24px 40px 40px;
	border-radius: 8px;
	box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
	background-color: #fff;
	max-width: 800px;
	max-height: calc(100vh - 40px);
	z-index: 1;
	overflow-y: scroll;
	-ms-overflow-style: none;

	@media screen and (--tablet), screen and (--phone) {
		max-width: 320px;
		padding: 25px 20px 24px 20px;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	& > .header {
		display: flex;
		align-items: center;
		margin-bottom: 16px;

		&.no-bottom {
			margin-bottom: 0;
		}

		& > :not(:last-child) {
			margin-right: 8px;
		}

		& > h5 {
			font-size: 20px;
			font-weight: 500;
			letter-spacing: 1.25px;
			color: var(--Primary);

			@media screen and (--tablet), screen and (--phone) {
				font-size: 16px;
				font-weight: 600;
				letter-spacing: 1px;
			}
		}

		& > .close {
			margin-left: auto;
		}
	}

	& > .body {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
		overflow-y: auto;
		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	& > .footer {
		display: flex;
		align-items: center;
		width: 100%;
	}
}
