.hamburger {
	width: 24px;
	height: 24px;
	position: relative;
	transform: rotate(0);
	transition: 0.5s ease-in-out;
	cursor: pointer;

	& > span {
		display: block;
		position: absolute;
		height: 2px;
		width: 18px;
		background: var(--Dark);
		border-radius: 1px;
		opacity: 1;
		left: 3px;
		transform: rotate(0);
		transition: all 0.25s ease-in-out;
	}

	& > span:nth-child(1) {
		top: 4px;
	}

	& > span:nth-child(2),
	& > span:nth-child(3) {
		top: 11px;
	}

	& > span:nth-child(4) {
		top: 18px;
	}

	&.white {
		& > span {
			background: var(--White);
		}
	}

	&.open {
		& > span:nth-child(1) {
			top: 11px;
			width: 0%;
			left: 50%;
		}

		& > span:nth-child(2) {
			transform: rotate(45deg);
		}

		& > span:nth-child(3) {
			transform: rotate(-45deg);
		}

		& > span:nth-child(4) {
			top: 11px;
			width: 0%;
			left: 50%;
		}
	}
}
