.loading {
	&.fixed {
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		&::before {
			content: '';
			background-color: white;
			opacity: 0.5;
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}

	& .svg-loader {
		display: flex;
		position: relative;
		align-content: space-around;
		justify-content: center;
	}

	& .loader-svg {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		fill: none;
		stroke-width: 3px;
		stroke-linecap: round;
		stroke: var(--Primary);
	}

	& .loader-svg.bg {
		stroke-width: 3px;
		stroke: #f2f2f2;
	}

	& .animate {
		stroke-dasharray: 242.6;
		animation: fill-animation 1s cubic-bezier(1, 1, 1, 1) 0s infinite;
	}
}

@keyframes fill-animation {
	0% {
		stroke-dasharray: 40 242.6;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 141.3;
		stroke-dashoffset: 141.3;
	}

	100% {
		stroke-dasharray: 40 242.6;
		stroke-dashoffset: 282.6;
	}
}
