.admin-layout {
	& header {
		display: flex;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 80px;
		padding: 0 60px;
		color: var(--Primary);
		background-color: var(--White);
		border-bottom: 1px solid #f2f2f2;
		transition: all 0.3s ease;
		z-index: 800;

		@media screen and (--tablet) {
			padding: 0 20px;
			height: 64px;
		}

		& > .logo {
			width: 136px;
			height: 20px;

			@media screen and (--tablet) {
				width: 149.7px;
				height: 22px;
			}
		}
	}
}
