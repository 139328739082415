.loading-layout {
	min-height: 100vh;
	background-image: url('../../images/contour-map.png');
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	@media screen and (--phone) {
		background-image: none;
	}
}
