.fetching-content-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: 'Montserrat', 'Noto Sans TC', sans-serif;
	font-size: 14px;
	letter-spacing: 0.8px;
	color: var(--Dark_grey);
	margin: 80px 0 40px 0;

	&.with-background {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		background-color: white;
		padding: 20px;
		box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.04);
	}
}
