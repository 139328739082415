.scrolling-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	flex-shrink: 0;
	height: 100%;
	position: relative;
	overflow-y: auto;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

.scrolling-container-gradient {
	position: absolute;
	width: 100%;
	height: 28px;
	z-index: 1;
	opacity: 1;
	pointer-events: none;
	transition: opacity 0.3s ease;

	&.hide {
		opacity: 0;
	}

	&.top {
		top: 0;
		background-image: linear-gradient(to top, rgba(255, 255, 255, 0), var(--White));
	}

	&.bottom {
		bottom: 0;
		background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--White));
	}
}

.scrolling-container-body {
	display: flex;
	flex-direction: column;
	flex: 1;
	flex-shrink: 0;
	overflow-y: auto;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none;
	}
}
