.navi-lang {
	display: flex;
	flex-direction: column;
	width: 100%;

	& > .title {
		display: flex;
		align-items: center;
		height: 50px;
		padding: 0 20px 0 16px;

		& > .icon {
			margin-right: 4px;
		}

		& > span {
			font-size: 14px;
			letter-spacing: 0.8px;
			color: var(--Dark);
		}
	}

	& > .wrapper {
		display: flex;
		flex-direction: column;
		flex: 1;
		position: relative;
		overflow-y: hidden;

		& > .list {
			display: flex;
			flex-direction: column;
			flex: 1;
			flex-shrink: 0;
			overflow-y: auto;
			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				display: none;
			}

			& > .item {
				display: flex;
				align-items: center;
				flex-shrink: 0;
				height: 50px;
				padding: 0 20px;
				font-size: 16px;
				font-weight: 300;
				letter-spacing: 1px;
				color: var(--Dark);
				cursor: pointer;

				&.active,
				&:hover {
					color: var(--Primary);
				}
			}

			& .itinerary-register {
				margin: 24px;
			}
		}

		& > .gradient {
			position: absolute;
			width: 100%;
			height: 120px;
			z-index: 1;
			opacity: 1;
			pointer-events: none;
			transition: opacity 0.3s ease;

			&.hide {
				opacity: 0;
			}

			&.top {
				top: 0;
				background-image: linear-gradient(to top, rgba(255, 255, 255, 0), var(--White));
			}

			&.bottom {
				bottom: 0;
				background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--White));
			}
		}
	}
}
