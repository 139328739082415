/* stylelint-disable no-descending-specificity */
.navigation.desktop {
	display: flex;
	flex-shrink: 0;

	&::-webkit-scrollbar {
		display: none;
	}

	& .button-navi {
		position: relative;
		font-size: 14px;
		letter-spacing: 0.8px;
		text-align: center;
		color: var(--Dark);

		&:not(:last-child) {
			margin-right: 40px;
		}

		&:hover {
			opacity: 0.8;

			& .link {
				color: var(--Primary);
			}
		}

		& .link {
			&.actived {
				color: var(--Primary);

				&::after {
					display: inline-flex;
					position: absolute;
					bottom: -5px;
					left: 50%;
					content: '';
					width: 20px;
					height: 2px;
					transform: translateX(-50%);
					border-radius: 1px;
					background-color: var(--Primary);
				}
			}
		}
	}
}

.navigation.mobile {
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	width: 100%;

	& .itinerary-register {
		margin: 24px;
	}
}

.button-navi-content {
	padding: 0 8px;
	z-index: 850;

	& > .button-navi-selection {
		width: 100%;
		padding: 6px 0;

		& > .option {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 40px;
			font-size: 14px;
			font-weight: 300;
			letter-spacing: 0.8px;
			color: var(--Dark);
			cursor: pointer;
			white-space: nowrap;
			padding: 0 8px;

			&.active,
			&:hover {
				color: var(--Primary);
			}
		}
	}
}

/* stylelint-enable no-descending-specificity */
