.modal {
	width: 320px;
	padding: 12px 16px 24px 16px;

	& .modal-info {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 24px;

		& > .modal-info-img {
			width: 80px;
			height: 80px;
			margin-top: 18px;
		}

		& > .modal-info-title {
			color: var(--Dark);
			font-size: 16px;
			margin-bottom: 12px;
		}

		& > .modal-info-content {
			color: #a0a0a0;
			font-size: 14px;
		}
	}

	& .footer {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	& .modal-button {
		width: 136px;
		height: 40px;

		&:first-child {
			margin-right: 8px;
		}

		&:last-child {
			margin-left: 8px;
		}
	}
}
