/* stylelint-disable no-descending-specificity */
.button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	cursor: pointer;
	height: 40px;
	border-style: solid;
	border-color: transparent;
	border-width: 1px;
	white-space: nowrap;
	transition: all 0.3s ease;

	& > span {
		font-size: 14px;
		letter-spacing: 0.8px;
		transition: color 0.3s ease;
	}

	& > .icon {
		margin-right: 4px;

		& svg path {
			transition: all 0.3s ease;
		}
	}

	&.disabled {
		cursor: not-allowed;
	}

	&.rectangle {
		border-radius: 4px;
		padding: 0 24px;
	}

	&.round {
		border-radius: 20px;
		padding: 0 20px;
	}

	&.small {
		height: 28px;
		padding: 0 8px;
	}

	&.white.filled {
		background-color: var(--White);

		& > span {
			color: var(--Primary);
		}

		& > .icon svg path {
			fill: var(--Primary);
		}

		&.disabled,
		&:not(.disabled):hover,
		&:not(.disabled):active {
			background-color: var(--White);
		}
	}

	&.white.outline {
		border-color: var(--White);
		background-color: transparent;

		& > span {
			color: var(--White);
		}

		& > .icon svg path {
			fill: var(--White);
		}

		&.disabled {
			border-color: var(--Dark_disable);

			& > span {
				color: var(--Dark_disable);
			}

			& > .icon svg path {
				fill: var(--Dark_disable);
			}
		}

		&:not(.disabled):hover {
			background-color: var(--White);

			& > span {
				color: var(--Primary);
			}

			& > .icon svg path {
				fill: var(--Primary);
			}
		}

		&:not(.disabled):active {
			border-color: var(--Dark40);

			& > span {
				color: var(--Primary_dark);
			}

			& > .icon svg path {
				fill: var(--Primary_dark);
			}
		}
	}

	&.primary.filled {
		background-color: var(--Button_primary_blue);

		& > span {
			color: var(--White);
		}

		& > .icon svg path {
			fill: var(--White);
		}

		&.disabled {
			background-color: var(--Primary_disable);
		}

		&:not(.disabled):hover {
			background-color: var(--Primary_hover);
		}

		&:not(.disabled):active {
			background-color: var(--Primary_dark);
		}
	}

	&.primary.outline {
		border-color: var(--Button_primary_blue);
		background-color: transparent;

		& > span {
			color: var(--Button_primary_blue);
		}

		& > .icon svg path {
			fill: var(--Button_primary_blue); /* var(--White); */
		}

		&.disabled {
			border-color: var(--Primary_disable);

			& > span {
				color: var(--Primary_disable);
			}

			& > .icon svg path {
				fill: var(--Primary_disable);
			}
		}

		/* In mobile button, click means hovering. It would make the hover style unchanged as clicking one time */
		&:not(.disabled):hover {
			background-color: var(--Button_primary_blue);

			& > span {
				color: var(--White);
			}

			& > .icon svg path {
				fill: var(--White);
			}
		}

		&:not(.disabled):active {
			background-color: var(--Primary_hover);

			& > span {
				color: var(--White);
			}

			& > .icon svg path {
				fill: var(--White);
			}
		}
	}

	&.blue.filled {
		background-color: var(--Primary);

		& > span {
			color: var(--White);
		}

		& > .icon svg path {
			fill: var(--White);
		}

		&.disabled {
			background-color: var(--Primary_disable);
		}

		&:not(.disabled):hover {
			background-color: var(--Primary_hover);
		}

		&:not(.disabled):active {
			background-color: var(--Primary_dark);
		}
	}

	&.blue.outline {
		border-color: var(--Primary);
		background-color: transparent;

		& > span {
			color: var(--Primary);
		}

		& > .icon svg path {
			fill: var(--Primary); /* var(--White); */
		}

		&.disabled {
			border-color: var(--Primary_disable);

			& > span {
				color: var(--Primary_disable);
			}

			& > .icon svg path {
				fill: var(--Primary_disable);
			}
		}

		/* In mobile button, click means hovering. It would make the hover style unchanged as clicking one time */
		&:not(.disabled):hover {
			background-color: var(--Primary);

			& > span {
				color: var(--White);
			}

			& > .icon svg path {
				fill: var(--White);
			}
		}

		&:not(.disabled):active {
			background-color: var(--Primary_hover);

			& > span {
				color: var(--White);
			}

			& > .icon svg path {
				fill: var(--White);
			}
		}
	}

	&.red.filled {
		background-color: var(--Funcational_error);

		& > span {
			color: var(--White);
		}

		& > .icon svg path {
			fill: var(--White);
		}

		&.disabled {
			background-color: var(--Dark_disable);
		}

		&:not(.disabled):hover {
			background-color: var(--Funcational_error_hover);
		}

		&:not(.disabled):active {
			background-color: var(--Funcational_error_dark);
		}
	}

	&.red.outline {
		border-color: var(--Funcational_error);
		background-color: transparent;

		& > span {
			color: var(--Funcational_error);
		}

		& > .icon svg path {
			fill: var(--Funcational_error); /* var(--White); */
		}

		&.disabled {
			border-color: var(--Dark_disable);

			& > span {
				color: var(--Dark_disable);
			}

			& > .icon svg path {
				fill: var(--Dark_disable);
			}
		}

		/* In mobile button, click means hovering. It would make the hover style unchanged as clicking one time */
		&:not(.disabled):hover {
			background-color: var(--Funcational_error);

			& > span {
				color: var(--White);
			}

			& > .icon svg path {
				fill: var(--White);
			}
		}

		&:not(.disabled):active {
			background-color: var(--Primary_hover);

			& > span {
				color: var(--White);
			}

			& > .icon svg path {
				fill: var(--White);
			}
		}
	}

	&.light-gray.filled {
		background-color: #f2f2f2;

		& > span {
			color: var(--Dark);
		}

		& > .icon svg path {
			fill: var(--Dark);
		}

		&.disabled {
			background-color: var(--Dark_disable);
		}

		&:not(.disabled):hover {
			background-color: var(--Dark_disable);
		}

		&:not(.disabled):active {
			background-color: var(--Dark40);
		}
	}

	&.light-gray.outline {
		border-color: #f2f2f2;
		background-color: white;

		& > span {
			color: var(--Dark);
		}

		& > .icon svg path {
			color: var(--Dark);
		}

		&.disabled {
			border-color: var(--Dark_disable);

			& > span {
				color: var(--Dark_disable);
			}

			& > .icon svg path {
				fill: var(--Dark_disable);
			}
		}

		&:not(.disabled):hover {
			border-color: #f2f2f2;

			& > span {
				color: var(--Dark80);
			}

			& > .icon svg path {
				fill: var(--Dark80);
			}
		}

		&:not(.disabled):active {
			border-color: #f2f2f2;

			& > span {
				color: var(--Dark80);
			}

			& > .icon svg path {
				fill: var(--Dark80);
			}
		}
	}

	&.shallow-gray.filled {
		background-color: #fafafa;

		& > span {
			color: var(--Primary);
		}

		& > .icon svg path {
			fill: var(--Primary);
		}

		&.disabled {
			background-color: #fafafa;

			& > span {
				color: #d8d8d8;
			}

			& > .icon svg path {
				fill: #d8d8d8;
			}
		}
	}

	&.shallow-gray.outline {
		border-color: #f2f2f2;
		background-color: fafafa;

		& > span {
			color: var(--Dark);
		}

		& > .icon svg path {
			color: var(--Dark);
		}

		&.disabled {
			border-color: var(--Dark_disable);

			& > span {
				color: var(--Dark_disable);
			}

			& > .icon svg path {
				fill: var(--Dark_disable);
			}
		}

		&:not(.disabled):hover {
			border-color: #f2f2f2;

			& > span {
				color: var(--Dark80);
			}

			& > .icon svg path {
				fill: var(--Dark80);
			}
		}

		&:not(.disabled):active {
			border-color: #f2f2f2;

			& > span {
				color: var(--Dark80);
			}

			& > .icon svg path {
				fill: var(--Dark80);
			}
		}
	}

	&.transparent.filled {
		background-color: transparent;

		& > span {
			color: var(--Primary);
		}

		& > .icon svg path {
			fill: var(--Primary);
		}

		&.disabled {
			background-color: transparent;

			& > span {
				color: #d8d8d8;
			}

			& > .icon svg path {
				fill: #d8d8d8;
			}
		}
	}

	&.transparent.outline {
		border-color: transparent;
		background-color: transparent;

		& > span {
			color: var(--Primary);
		}

		& > .icon svg path {
			color: var(--Primary);
		}

		&.disabled {
			border-color: var(--Dark_disable);

			& > span {
				color: var(--Dark_disable);
			}

			& > .icon svg path {
				fill: var(--Dark_disable);
			}
		}

		&:not(.disabled):hover {
			border-color: transparent;

			& > span {
				color: var(--Primary);
			}

			& > .icon svg path {
				fill: var(--Primary);
			}
		}

		&:not(.disabled):active {
			border-color: transparent;

			& > span {
				color: var(--Primary);
			}

			& > .icon svg path {
				fill: var(--Primary);
			}
		}
	}

	&.gradient.filled {
		background-image: linear-gradient(252deg, #2d8fe3, var(--Primary));
		border: none;

		& > span {
			color: var(--White);
		}

		& > .icon svg path {
			fill: var(--White);
		}

		&.disabled {
			background-color: var(--Primary_disable);
		}

		&:not(.disabled):hover {
			background-color: var(--Primary_hover);
		}

		&:not(.disabled):active {
			background-color: var(--Primary_dark);
		}
	}
}

/* stylelint-enable no-descending-specificity */
