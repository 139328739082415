.html-content {
	&.preline {
		white-space: pre-line;
	}

	& img {
		width: 100%;
	}

	&.with-style {
		padding-bottom: 15px;

		& p {
			font-size: 14px;
			font-weight: 300;
			line-height: 1.86;
			letter-spacing: 0.8px;
			color: var(--Dark);

			/* &:not(:last-child) {
				margin-bottom: 40px;

				@media screen and (--tablet), screen and (--phone) {
					margin-bottom: 20px;
				}
			} */
		}

		& img {
			border-radius: 6.4px;
			box-shadow: 0 8px 16px 0 #d3d3d3;

			&:not(:last-child) {
				margin-bottom: 40px;
			}
		}

		& ul {
			list-style: none;
			padding: 0;
			margin: 0;

			& li {
				font-size: 14px;
				font-weight: 300;
				line-height: 1.86;
				letter-spacing: 0.8px;
				color: var(--Dark);
			}
		}
	}
}
